import { get, post, destroy, patch } from './httpClient';

export const getDispute = (params) => get('/dispute/get-dispute', params);
export const syncDispute = (params) => post('/dispute/sync-dispute', params);
export const updateDispute = (id, data) =>
  patch(`/dispute/update-dispute/${id}`, data);

export const getLabel = (params) => get('/label/get-label', params);
export const createLabel = (data) => post('/label/create-label', data);
export const updateLabel = (id, data) =>
  patch('/label/update-label/' + id, data);
