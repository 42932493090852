import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Table,
  Modal,
  Form,
  Divider,
  Menu,
  Dropdown,
  DatePicker,
  notification,
  Popconfirm,
} from 'antd';
import styles from './accountCredit.module.scss';
// api
import {
  addPaygate,
  checkingProxy,
  createAccountCredit,
  getAccountCredit,
  updateAccountCredit,
  getPaygate,
  deletePaygate,
} from 'apis/account-credit';
import { decodeToken } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { ACTION, PERMISSIONS } from 'consts';
import { getTimeRange } from 'utils';
import Permission from 'components/permission';
import moment from 'moment';
import { SettingOutlined, SwapOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import { getsAllUsers } from 'apis/users';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AccountCredit() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [updateID, setUpdateID] = useState();
  const [usePaypal, setUsePaypal] = useState(true);
  const [filter, setFilter] = useState();
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [showPaygateInfo, setShowPaygateInfo] = useState({
    visible: false,
    data: {},
  });
  const [pagination, setPagination] = useState({ page: 1, page_size: 10 });
  const [totalRecord, setTotalRecord] = useState(0);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [userList, setUserList] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [paygateFilterList, setPaygateFilterList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [detailUpdate, setDetailUpdate] = useState({
    title: '',
    placeholder: '',
    key: '',
  });
  const dispatch = useDispatch();
  const [assignUserForm] = Form.useForm();
  const setDetail = (title, placeholder, key) =>
    setDetailUpdate({ title, placeholder, key });
  const columns = [
    {
      title: 'Paygate Name',
      dataIndex: 'paygate_name',
      render(data, record) {
        return (
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              setUpdateID(record.config_id);
              form.setFieldsValue({ ...record, type: 'update' });
              setShowConfigModal(true);
            }}
          >
            {data}
          </span>
        );
      },
    },
    {
      title: 'Chanel',
      dataIndex: 'chanel',
      render(data) {
        return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
      },
    },
    {
      title: 'Info Paygate',
      render(data) {
        return (
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => setShowPaygateInfo({ visible: true, data })}
          >
            See Details
          </span>
        );
      },
    },
    {
      title: 'Proxy Host',
      dataIndex: 'proxy_ip',
    },
    {
      title: 'Proxy Port',
      dataIndex: 'proxy_port',
    },
    {
      title: 'Proxy Account',
      dataIndex: 'proxy_username',
    },
    {
      title: 'Status',
      dataIndex: 'proxy_status',
      render(data) {
        return (
          <span
            style={{
              color:
                data && data.toUpperCase() === 'NOT WORKING'
                  ? 'red'
                  : '#3AAC30',
            }}
          >
            {data.toUpperCase()}
          </span>
        );
      },
    },
    {
      title: 'Create Date',
      dataIndex: 'create_date',
      render(data) {
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Action',
      render(data) {
        return (
          <>
            <CheckProxyButton data={data} />{' '}
            <Dropdown overlay={() => menuSetting(data)}>
              <SettingOutlined
                style={{ fontSize: 20, color: 'rgb(24, 144, 255)' }}
              />
            </Dropdown>
          </>
        );
      },
    },
  ];
  const changePagination = (page, page_size) =>
    setPagination({ page, page_size });
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const CheckProxyButton = ({ data, reload }) => {
    const [loading, setLoading] = useState(false);
    const checkProxy = async (data) => {
      try {
        setLoading(true);
        const res = await checkingProxy(data);
        if (res.data.success) {
          notification.success({ message: res.data.mess });
        } else {
          notification.error({ message: res.data.mess });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    return (
      <Button
        type="primary"
        loading={loading}
        onClick={() =>
          checkProxy({
            proxy_method: 'http',
            proxy_ip: data.proxy_ip,
            proxy_port: data.proxy_port,
            proxy_username: data.proxy_username,
            proxy_password: data.proxy_password,
          })
        }
      >
        Check Proxy
      </Button>
    );
  };

  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect);
  const changeRange = (date, dateString) => {
    setFilter({
      ...filter,
      from_date: dateString[0],
      to_date: dateString[1],
    });
  };
  const changeTimeOption = (value) => {
    let tmp = getTimeRange(value);
    setFilter({
      ...filter,
      ...tmp,
    });
  };
  const onFinish = async (value) => {
    let tmp = { ...value };
    delete tmp.type;
    let user = decodeToken(localStorage.accessToken);
    const body = {
      ...tmp,
      creator: user.username,
    };
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res =
        value.type == 'update'
          ? await updateAccountCredit(updateID, body)
          : await createAccountCredit(body);
      if (res.data.success) {
        notification.success({ message: `${value.type} Successful` });
        setShowConfigModal(false);
        form.resetFields();
        getAccount();
      } else {
        notification.error({
          message: `${value.type} Fail`,
          description: res.data.message || res.data.mess,
        });
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: ACTION.LOADING, data: false });
      notification.error({ message: `${value.type} Fail` });
    }
  };
  const onBulkUpdate = async (value) => {
    try {
      const res = await Promise.all(
        selectedRowKeys.map((e) => {
          return updateAccountCredit(e, value);
        })
      );
      if (res.reduce((a, b) => a && b.data.success, true)) {
        notification.success({
          message: `${
            Object.keys(value)[0] === 'delete' ? 'Delete' : 'Update'
          } Successful`,
        });
        setSelectedRowKeys([]);
        formUpdate.resetFields();
        getAccount();
        setShowUpdate(false);
      } else {
        notification.error({
          message: `${
            Object.keys(value)[0] === 'delete' ? 'Delete' : 'Update'
          } fail`,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Fail' });
    }
  };
  const requiredField = (fieldName) => {
    return [{ required: true, message: `${fieldName} is required` }];
  };
  const getAccount = async (params) => {
    try {
      setTableLoading(true);
      const res = await getAccountCredit({ ...pagination, ...params });
      if (res.data.success) {
        setAccountList(res.data.data && res.data.data.filter((e) => !e.delete));
        setTotalRecord(res.data.count);
      }
      setTableLoading(false);
    } catch (err) {
      console.log(err);
      setTableLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const addPaygateToUser = async (value, paygate_name) => {
    try {
      console.log(showAssignUser);
      const dataList = value.username
        .filter((e) => !showAssignUser.data._users.includes(e))
        .map((e) => {
          return {
            username: e,
            paygate_name,
          };
        });
      if (!dataList.length) {
        notification.success({ message: 'Assign user Success' });
        setShowAssignUser({ visible: false });
        getListPaygateName();
        getAccount();
        return;
      }

      const res = await Promise.all(
        dataList.map((e) => {
          return addPaygate(e);
        })
      );
      if (res.reduce((a, b) => a && b.data.success, true)) {
        notification.success({ message: 'Assign user Success' });
        setShowAssignUser({ visible: false });
        getListPaygateName();
        getAccount();
      } else {
        const usernameErr = res.filter((e) => !e.data.success);
        notification.error({
          message: 'Assign User Fail!',
          description:
            usernameErr.length > 0 && `username: ${usernameErr.join(',')}`,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Assign User Fail' });
    }
  };

  const deleteUserFromPaygate = async (username, paygate_name) => {
    try {
      const res = await deletePaygate({ username, paygate_name });
      if (res.data.success) {
        notification.success({ message: 'Remove ' + username + ' Success' });
      }
    } catch (err) {
      notification.error({ message: 'Remove ' + username + ' Fail' });
    }
  };

  const getAllUser = async () => {
    try {
      const res = await getsAllUsers();
      if (res.data.success) {
        setUserList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const menuUpdate = () => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setDetail('Assign Paygate Name', 'Paygate Name', 'paygate_name');
          setShowUpdate(true);
        }}
      >
        Assign Paygate Name
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDetail('Assign Channel', 'Channel', 'chanel');
          setShowUpdate(true);
        }}
      >
        Assign Channel
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDetail('Assign Proxy Host', 'Proxy ID', 'proxy_ip');
          setShowUpdate(true);
        }}
      >
        Assign Proxy ID
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDetail('Assign Proxy Port', 'Proxy Port', 'proxy_port');
          setShowUpdate(true);
        }}
      >
        Assign Proxy Port
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDetail('Assign Proxy Account', 'Proxy Username', 'proxy_account');
          setShowUpdate(true);
        }}
      >
        Assign Proxy Username
      </Menu.Item>
    </Menu>
  );

  const getListPaygateName = async () => {
    try {
      const res = await getPaygate();
      if (res.data.success) {
        setPaygateFilterList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setUserAssigned = (data) => {
    let tmp = [];
    paygateFilterList.forEach((e) => {
      if (e.paygates.includes(data.paygate_name)) {
        tmp.push(e.username);
      }
    });
    setAssignList(tmp);
    console.log(tmp);
  };
  const menuSetting = (data) => {
    return (
      <Menu>
        <Menu.Item
          icon={<SwapOutlined />}
          onClick={() => {
            setShowAssignUser({ visible: true, data });
            setAssignList(data._users);
            // setUserAssigned(data);
          }}
        >
          Assign User
        </Menu.Item>
      </Menu>
    );
  };
  useEffect(() => {
    getAccount(filter);
  }, [filter, pagination]);
  useEffect(() => {
    getAllUser();
    getListPaygateName();
  }, []);
  useEffect(() => {
    assignUserForm.setFieldsValue({ username: assignList });
  }, [assignList]);
  return (
    <div className={styles['account-credit']}>
      <Row
        justify="space-between"
        style={{
          borderBottom: 'solid 1px #A8A1A1',
          paddingBottom: '15px',
          marginBottom: 20,
        }}
      >
        <Col className={styles['account-title']}>Account Credits</Col>
        <Col>
          <Row gutter={10}>
            <Col>
              <Button
                type="primary"
                href="https://s3.ap-northeast-1.wasabisys.com/admin-order/2021/10/02/c30333ca-9713-41ca-a897-4f68d22dc89e/HƯỚNG DẪN LẤY KEY PAYPAL.docx"
              >
                Get key Paypal
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                href="https://s3.ap-northeast-1.wasabisys.com/admin-order/2021/10/02/c30333ca-9713-41ca-a897-4f68d22dc89e/HƯỚNG DẪN LẤY KEY STRIPE.docx"
              >
                Get key Stripe
              </Button>
            </Col>
            <Col>
              <Permission permissions={[PERMISSIONS.add_pay_gate]}>
                <Button
                  type="primary"
                  onClick={() => {
                    form.setFieldsValue({ type: 'create' });
                    setShowConfigModal(true);
                  }}
                >
                  Add Paygate
                </Button>
              </Permission>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={30} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Input
            allowClear
            placeholder="Search by Paygate Name"
            onChange={(e) =>
              setFilter({ ...filter, paygate_name: e.target.value })
            }
          />
        </Col>
        <Col span={8}>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect();
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect();
            }}
            style={{ width: '100%' }}
            placeholder="Filter by time"
            allowClear
            onChange={async (value) => {
              if (isOpenSelect) toggleOpenSelect();
              changeTimeOption(value);
            }}
            dropdownRender={(menu) => (
              <div>
                <RangePicker
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect();
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect();
                  }}
                  style={{ width: '100%' }}
                  onChange={changeRange}
                />
                {menu}
              </div>
            )}
          >
            <Option value="to_day">Today</Option>
            <Option value="yesterday">Yesterday</Option>
            <Option value="this_week">This week</Option>
            <Option value="last_week">Last week</Option>
            <Option value="last_month">Last month</Option>
            <Option value="this_month">This month</Option>
            <Option value="this_year">This year</Option>
            <Option value="last_year">Last year</Option>
          </Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }} gutter={20}>
        {selectedRowKeys.length > 0 && (
          <>
            <Permission permissions={[PERMISSIONS.update_pay_gate]}>
              <Col>
                <Dropdown overlay={menuUpdate}>
                  <Button type="primary">Update</Button>
                </Dropdown>
              </Col>
            </Permission>
            <Permission permissions={[PERMISSIONS.delete_pay_gate]}>
              <Col>
                <Popconfirm
                  title="Are you sure to delete this account?"
                  onConfirm={() => onBulkUpdate({ delete: true })}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" danger>
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
            </Permission>
          </>
        )}
      </Row>
      <Table
        rowKey="config_id"
        loading={tableLoading}
        columns={columns}
        dataSource={accountList}
        rowSelection={rowSelection}
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={{ onChange: changePagination, total: totalRecord }}
      />
      <Modal
        title="Paygate Name"
        visible={showConfigModal}
        onCancel={() => {
          form.resetFields();
          setShowConfigModal(false);
        }}
        width={700}
        centered
        footer=""
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="paygate_name"
                label="Paygate Name"
                rules={requiredField('Paygate Name')}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Channel"
                name="chanel"
                rules={requiredField('Channel')}
                initialValue="Paypal"
              >
                <Select onChange={(e) => setUsePaypal(e === 'Paypal')}>
                  <Select.Option value="Paypal">Paypal</Select.Option>
                  <Select.Option value="Stripe">Stripe</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {usePaypal && (
              <Col span={12}>
                <Form.Item
                  label="Client ID"
                  name="client_id"
                  rules={requiredField('Client ID')}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label="Secret key"
                name="secret_key"
                rules={requiredField('Secret key')}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Proxy IP"
                name="proxy_ip"
                rules={requiredField('Proxy IP')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Proxy port"
                name="proxy_port"
                rules={requiredField('Proxy port')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="proxy_username" label="Proxy username">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="proxy_password" label="Proxy password">
                <Input />
              </Form.Item>
            </Col>

            <Form.Item name="type">
              <Input hidden />
            </Form.Item>
          </Row>
          <Divider />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={detailUpdate.title}
        visible={showUpdate}
        onCancel={() => setShowUpdate(false)}
        footer=""
      >
        <Form form={formUpdate} onFinish={onBulkUpdate}>
          <Form.Item name={detailUpdate.key}>
            {detailUpdate.key === 'chanel' ? (
              <Select placeholder={detailUpdate.placeholder}>
                <Select.Option value="Stripe">Stripe</Select.Option>
                <Select.Option value="Paypal">Paypal</Select.Option>
              </Select>
            ) : detailUpdate.key === 'proxy_account' ? (
              <Row gutter={20}>
                <Col span={12}>
                  <Input placeholder="Proxy account" />
                </Col>
                <Col span={12}>
                  <Input.Password placeholder="Proxy password" />
                </Col>
              </Row>
            ) : (
              <Input placeholder={detailUpdate.placeholder} />
            )}
          </Form.Item>
          <Row justify="center">
            <Button type="primary" htmlType="submit">
              Apply in Bulk
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="See Details Info Paygate"
        visible={showPaygateInfo.visible}
        onCancel={() => setShowPaygateInfo({ visible: false, data: {} })}
        footer=""
        centered
      >
        <Row>
          <Col span={5}>
            <b>Client ID:</b>
          </Col>
          <Col span={19}>{showPaygateInfo.data.client_id}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <b>Serect key:</b>
          </Col>
          <Col span={19}>{showPaygateInfo.data.secret_key}</Col>
        </Row>
      </Modal>
      <Modal
        title="Assign User"
        visible={showAssignUser.visible}
        onCancel={() => setShowAssignUser({ visible: false })}
        footer=""
        centered
      >
        <Form
          onFinish={(value) =>
            addPaygateToUser(
              value,
              showAssignUser.data && showAssignUser.data.paygate_name
            )
          }
          form={assignUserForm}
        >
          <Form.Item name="username">
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              onChange={(e) => setAssignList(e)}
              onDeselect={(e) =>
                deleteUserFromPaygate(
                  e,
                  showAssignUser.data && showAssignUser.data.paygate_name
                )
              }
            >
              {userList.map((e) => (
                <Select.Option value={e.username}>{e.username}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Assign
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
