import moment from 'moment'
import S3 from 'aws-s3'

export function formatCash(str) {
  if (str)
    return str
      .toString()
      .split('')
      .reverse()
      .reduce((prev, next, index) => {
        return (index % 3 ? next : next + ',') + prev
      })
  else return 0
}

export const decodeJWT = (_token) => {
  if (typeof _token !== 'string') {
    return false
  }
  const _splitToken = _token.split('.')
  if (_splitToken.length !== 3) {
    return false
  }
  try {
    const payload = JSON.parse(atob(_splitToken[1]))
    // if (payload.role === 'client') {
    //   if (!payload.permissions) {
    //     payload.permissions = []
    //   }
    //   payload.permissions = [
    //     ...payload.permissions,
    //   ]
    // }
    return payload
  } catch (error) {
    return null
  }
}

export const uploadFiles = async (files) => {
  try {
    /* config s3 */
    const _d = moment(new Date()).format('YYYY/MM/DD')
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    }
    /* config s3 */

    const listPromise = files.map(async (file) => {
      const ReactS3Client = new S3(config)
      let fileName = file.name.split('.')

      //check type file và thay đổi type file
      //check đuôi file là gì thì set type trong object file giá trị đó
      Object.defineProperty(file, 'type', {
        value: `application/${fileName[fileName.length - 1]}`,
        writable: true,
      })

      fileName.splice(fileName.length - 1, 1)

      const res = await ReactS3Client.uploadFile(
        file,
        _d + '/' + fileName.toString().replaceAll(',', '.')
      )
      if (res && res.status === 204) return res.location
    })

    const results = Promise.all(listPromise)
    return results || []
  } catch (error) {
    console.log(error)
  }
}

export const uploadFile = async (file) => {
  try {
    /* config s3 */
    const _d = moment(new Date()).format('YYYY/MM/DD')
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    }
    /* config s3 */

    const ReactS3Client = new S3(config)
    let fileName = file.name.split('.')

    //check type file và thay đổi type file
    //check đuôi file là gì thì set type trong object file giá trị đó
    Object.defineProperty(file, 'type', {
      value: `application/${fileName[fileName.length - 1]}`,
      writable: true,
    })

    fileName.splice(fileName.length - 1, 1)

    const res = await ReactS3Client.uploadFile(
      file,
      _d + '/' + fileName.toString().replaceAll(',', '.')
    )
    if (res && res.status === 204) return res.location
    return ''
  } catch (error) {
    console.log(error)
  }
}

export const getTimeRange = (value) => {
  switch (value) {
    case 'to_day':
      return {
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    case 'yesterday':
      return {
        from_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        to_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      }
    case 'this_week':
      return {
        from_date: moment().startOf('week').format('YYYY-MM-DD'),
        to_date: moment().endOf('week').format('YYYY-MM-DD'),
      }
    case 'last_week':
      return {
        from_date: moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD'),
        to_date: moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD'),
      }
    case 'this_month':
      return {
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    case 'last_month':
      return {
        from_date: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        to_date: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case 'this_year':
      return {
        from_date: moment().startOf('years').format('YYYY-MM-DD'),
        to_date: moment().endOf('years').format('YYYY-MM-DD'),
      }
    case 'last_year':
      return {
        from_date: moment()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD'),
        to_date: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD'),
      }
    default:
      return {
        from_date: '',
        to_date: '',
      }
  }
}
