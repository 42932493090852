import { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Steps,
  Input,
  notification,
  Select,
  Button,
  Divider,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './dispute.module.scss';
import { updateDispute, getLabel, getDispute } from 'apis/dispute';
import moment from 'moment';
const { Step } = Steps;
const { Option } = Select;
// const useQuery = () => new URLSearchParams(useLocation().search);
export default function DisputeDetail() {
  const history = useHistory();
  const location = useLocation()
  const [dataUpdate, setDataUpdate] = useState({});
  const [labelList, setLabelList] = useState([]);
  const [DetailDispute, setDetailDispute] = useState({});
  // let query = useQuery();
  const UpdateDetail = async () => {
    try {
      const res = await updateDispute(DetailDispute._id, dataUpdate);
      if (res.data.success) {
        notification.success({ message: 'Update successful' });
      } else {
        notification.error({ message: 'Update fail' });
      }
    } catch (e) {
      console.log(e);
      notification.error({ message: 'Fail' });
    }
  };
  const getAllLabel = async () => {
    try {
      const res = await getLabel();
      if (res.data.success) {
        setLabelList(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getDisputeDetail = async (params) => {
    try {
      const res = await getDispute(params);
      if (res.data.success) {
        setDetailDispute(res.data.data && res.data.data[0]);
        setDataUpdate({
          tracking: res.data.data && res.data.data[0].tracking,
          note: res.data.data && res.data.data[0].note,
          label_id: res.data.data && res.data.data[0].label_id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const dispute_id = new URLSearchParams(location.search).get('dispute_id');
    if (dispute_id) {
      getAllLabel();
      getDisputeDetail({dispute_id});
    }
    else {
      history.goBack()
      return
    }
  }, []);
  return (
    <div className={styles['dispute']}>
      <Row
        justify="space-between"
        style={{
          borderBottom: 'solid 1px #A8A1A1',
          paddingBottom: '15px',
          marginBottom: 20,
        }}
      >
        <Col
          className={styles['dispute-title']}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/dispute')}
        >
          <ArrowLeftOutlined /> Dispute details
        </Col>
      </Row>
      <div style={{ width: 800, margin: 'auto' }}>
        <Steps progressDot>
          <Step title="Buyer fields this case" />
          <Step title="We need a resonsive" />
          <Step title="We'll review the info" />
          <Step title="Estimated case resolution" />
        </Steps>
        <Row gutter={[10, 10]} style={{ fontSize: '16px', marginTop: 20 }}>
          <Col span={12}>Buyer Infomation</Col>
          <Col span={12}>
            <div>
              {DetailDispute.extension &&
                DetailDispute.extension.customer_detail.name}
            </div>
            <div>
              <a
                href={`mailTo:${
                  DetailDispute.extension &&
                  DetailDispute.extension.customer_detail.email
                }`}
              >
                {DetailDispute.extension &&
                  DetailDispute.extension.customer_detail.email}
              </a>
            </div>
          </Col>

          <Col span={12}>Transaction amount</Col>
          <Col span={12}>
            {DetailDispute.extension &&
              DetailDispute.extension.order_detail.gross_amount.value}{' '}
            {DetailDispute.extension &&
              DetailDispute.extension.order_detail.gross_amount.currency_code}
          </Col>

          <Col span={12}>Refund requested</Col>
          <Col span={12}>
            {DetailDispute.extension &&
              DetailDispute.extension.dispute_detail.dispute_amout.value}{' '}
            {DetailDispute.extension &&
              DetailDispute.extension.dispute_detail.dispute_amout
                .currency_code}
          </Col>

          <Col span={12}>Transaction ID</Col>
          <Col span={12}>
            {DetailDispute.transaction_id && DetailDispute.transaction_id}
          </Col>

          {/* <Col span={12}>Invoice ID</Col>
          <Col span={12}>{history.location.state.invoice_id}</Col> */}

          <Col span={12}>Order ID</Col>
          <Col span={12}>{DetailDispute.order_id}</Col>

          <Col span={12}>Shipping address</Col>
          <Col span={12}>
            {DetailDispute.extension &&
              DetailDispute.extension.customer_detail.address}
          </Col>
          <Col span={12}>Date reported</Col>
          <Col span={12}>{moment(DetailDispute.create_date).format('LL')}</Col>
          {/* <Col span={12}>Buyer notes</Col>
          <Col span={12}>
            {history.location.state.extension.dispute_detail.messages &&
              history.location.state.extension.dispute_detail.messages[0]
                .content}
          </Col> */}
          <Col span={12}>Message</Col>
          <Col span={12}>
            {DetailDispute.extension &&
              DetailDispute.extension.dispute_detail.messages &&
              DetailDispute.extension.dispute_detail.messages.map((e) => {
                return (
                  <div style={{ marginBottom: 8 }}>
                    <span style={{ color: 'blue' }}>{e.posted_by}</span>
                    &nbsp;&nbsp;
                    <span style={{ color: '#16a085', fontSize: 16 }}>
                      {moment(e.time_posted).format('DD-MM-YYYY hh:mm:ss')}
                    </span>
                    <div>{e.content}</div>
                  </div>
                );
              })}
          </Col>

          <Col span={12}>Tracking</Col>
          <Col span={12}>
            <Input
              value={dataUpdate.tracking}
              onChange={(e) =>
                setDataUpdate({ ...dataUpdate, tracking: e.target.value })
              }
            />
          </Col>
          <Col span={12}>Note</Col>
          <Col span={12}>
            <Input.TextArea
              value={dataUpdate.note}
              onChange={(e) =>
                setDataUpdate({ ...dataUpdate, note: e.target.value })
              }
            />
          </Col>
          <Col span={12}>Label</Col>
          <Col span={12}>
            <Select
              value={dataUpdate.label_id}
              style={{ width: 350 }}
              onChange={(e) => setDataUpdate({ ...dataUpdate, label_id: e })}
            >
              {labelList.map((e) => (
                <Option value={e.label_id}>{e.name}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Button type="primary" onClick={UpdateDetail}>
            Update
          </Button>
        </Row>
      </div>
    </div>
  );
}
