export const ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  GET_PRODUCTS: 'GET_PRODUCTS',
}

export const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
  MAIL: '/mail',
  USERS: '/users',
  ACCOUNT_SETTING: '/account-setting',
  DISPUTE: '/dispute',
  DISPUTE_DETAIL: '/dispute-detail',
}

export const PERMISSIONS = {
  view_dashboard: 'view_dashboard',
  view_order: 'view_order',
  repair_order: 'repair_order',
  view_tracking_order: 'view_tracking_order',
  update_status_order: 'update_status_order',
  update_design_order: 'update_design_order',
  cancel_product_in_order: 'cancel_product_in_order',
  approve_product_in_order: 'approve_product_in_order',
  rebuild_product_in_order: 'rebuild_product_in_order',
  update_product_in_order: 'update_product_in_order',
  view_news: 'view_news',
  update_news: 'update_news',
  create_news: 'create_news',
  view_product: 'view_product',
  create_product: 'create_product',
  update_product: 'update_product',
  delete_product: 'delete_product',
  view_product_type: 'view_product_type',
  create_product_type: 'create_product_type',
  edit_product_type: 'edit_product_type',
  delete_product_type: 'delete_product_type',
  duplicate_product_type: 'duplicate_product_type',
  update_active_product_type: 'update_active_product_type',
  view_app_setting: 'view_app_setting',
  view_user_management: 'view_user_management',
  view_tracking_log: 'view_tracking_log',
  setting: 'setting',
  view_logs_app: 'view_logs_app',
  view_sites: 'view_sites',
  view_category: 'view_category',
  create_category: 'create_category',
  edit_category: 'edit_category',
  remove_category: 'remove_category',
  view_carrier: 'view_carrier',
  create_carrier: 'create_carrier',
  edit_carrier: 'edit_carrier',
  update_active_carrier: 'update_active_carrier',
  view_content_setting: 'view_content_setting',
  view_seller_level: 'view_seller_level',
  create_level: 'create_level',
  edit_shipping_info: 'edit_shipping_info',
  assign_supplier_order: 'assign_supplier_order',
  assign_producttype_order: 'assign_producttype_order',
  import_tracking_number: 'import_tracking_number',
  import_orders: 'import_orders',
  add_site: 'add_site',
  edit_site: 'edit_site',
  remove_site: 'remove_site',
  assign_seller_site: 'assign_seller_site',
  assign_view_order: 'assign_view_order',
  edit_access_file_design: 'edit_access_file_design',
  update_active_site: 'update_active_site',
  create_new_user: 'create_new_user',
  edit_user: 'edit_user',
  remove_user: 'remove_user',
  view_order_archive: 'view_order_archive',
  add_file_design: 'add_file_design',
  view_dispute: 'view_dispute',
  view_account_setting: 'view_account_setting',
  add_pay_gate: 'add_pay_gate',
  update_pay_gate: 'update_pay_gate',
  delete_pay_gate: 'delete_pay_gate',
  view_product_feed: 'view_product_feed',
  view_collection: 'view_collection',
}

export const IMAGE_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/09/12/8f70ac1d-4070-46cb-9ed9-577cd38d7f3c_noimage.jpg'

export const VERSION_APP = '0.0.2'
