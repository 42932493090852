const disputeColumns = [
  {
    title: 'Channel',
    dataIndex: 'chanel',
    key: 0,
  },
  {
    title: 'Paygate Name',
    dataIndex: 'paygate_name',
    key: 1,
  },
  {
    title: 'Dispute_id',
    dataIndex: 'dispute_id',
    key: 2,
  },
  {
    title: 'Transaction_id',
    dataIndex: 'transaction_id',
    key: 3,
  },
  {
    title: 'Order name',
    dataIndex: 'order_name',
    key: 4,
  },
  {
    title: 'Order_id',
    dataIndex: 'order_id',
    key: 5,
  },
  {
    title: 'Customer name',
    dataIndex: 'extension',
    key: 6,
  },
  {
    title: 'Create date',
    dataIndex: 'create_date',
    key: 7,
  },
  {
    title: 'Due date',
    dataIndex: 'due_date',
    key: 8,
  },
  {
    title: 'message',
    dataIndex: 'extension',

    key: 9,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 10,
  },
  {
    title: 'Tracking',
    dataIndex: 'tracking',
    key: 11,
  },
  {
    title: 'label',
    key: 12,
    dataIndex: '_labels',
  },
  {
    title: 'Note',
    key: 13,
    dataIndex: 'note',
  },
];

export default disputeColumns;
