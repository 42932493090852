import React, { useState, useEffect } from 'react'
import styles from './ui.module.scss'
import { useSelector } from 'react-redux'
import { ROUTES, PERMISSIONS, VERSION_APP } from 'consts'
import Permission from 'components/permission'

//components antd
import { Layout, Menu, Popover } from 'antd'

//icons antd
import {
  MenuFoldOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
} from '@ant-design/icons'

import { Link, useRouteMatch, useLocation } from 'react-router-dom'

const { Sider, Content } = Layout
export default function UI(props) {
  const location = useLocation()
  const [balance, setBalance] = useState({})
  const [username, setUsername] = useState('')
  const dataUser = useSelector((state) => state.login.dataUser)
  const settingApp = useSelector((state) => state.setting)

  const routeMatch = useRouteMatch()
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => setCollapsed(!collapsed)

  const onClickLogout = () => {
    let tmp = localStorage.DisputeColumns
    localStorage.clear()
    if (tmp) localStorage.setItem('DisputeColumns', tmp)
  }

  const content = (
    <div className={styles['popoper']}>
      <Link to="/login" onClick={onClickLogout}>
        <div className={styles['popoper_parent_bottom']}>
          <div>
            <LogoutOutlined />
          </div>
          <div>Logout</div>
        </div>
      </Link>
    </div>
  )

  const MENUS = [
    {
      path: ROUTES.USERS,
      title: 'User management',
      permissions: [],
      icon: <UsergroupAddOutlined />,
    },
    {
      path: 'resolution',
      title: 'Resolution',
      permissions: [],
      icon: (
        <svg
          style={{ width: 14, height: 14 }}
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="credit-card-front"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          class="svg-inline--fa fa-credit-card-front fa-w-18 fa-3x">
          <path
            fill="currentColor"
            d="M528 31H48C21.5 31 0 52.5 0 79v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V79c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V79c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v352zm-352-68v8c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v8c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12zM488 95h-80c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm-8 64h-64v-32h64v32zM260 319h-56c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm28-12v-40c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12zm-192 0v-40c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12zm384-40v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12z"
            class=""></path>
        </svg>
      ),
      menuItems: [
        {
          path: ROUTES.DISPUTE,
          title: 'Dispute Management',
          permissions: [],
        },
        {
          path: ROUTES.ACCOUNT_SETTING,
          title: 'Account Setting',
          permissions: [],
        },
      ],
    },
  ]

  const renderMenuItem = (_menu) => (
    <Permission permissions={_menu.permissions} key={_menu.path}>
      {_menu.menuItems ? (
        <Menu.SubMenu
          key={_menu.path}
          icon={_menu.icon}
          title={<span style={{ fontWeight: 500 }}>{_menu.title}</span>}>
          {_menu.menuItems.map((e) => (
            <Permission permissions={e.permissions}>
              <Menu.Item
                key={e.path}
                icon={e.icon}
                style={{
                  backgroundColor: location.pathname === e.path && '#1890FF',
                }}>
                <Link
                  to={e.path}
                  style={{
                    fontWeight: 500,
                    color: location.pathname === e.path && 'white',
                  }}>
                  {e.title}
                </Link>
              </Menu.Item>
            </Permission>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item icon={_menu.icon} key={_menu.path}>
          <Link to={_menu.path} style={{ fontWeight: 500 }}>
            {_menu.title}
          </Link>
        </Menu.Item>
      )}
    </Permission>
  )

  useEffect(() => {
    //Saller và supplier thấy đc mục balance
    //Admin ko thấy đc
    if (
      (localStorage.getItem('role') &&
        JSON.parse(localStorage.getItem('role')) === 'Supplier') ||
      JSON.parse(localStorage.getItem('role')) === 'Seller'
    ) {
      MENUS.push({
        path: ROUTES.BALANCE,
        title: 'Balance',
        permissions: [],
        icon: <DollarOutlined />,
      })
    }

    //Saller và supplier sẽ ko thấy đc mục menu transaction
    //Admin sẽ thấy đc
    if (
      localStorage.getItem('role') &&
      JSON.parse(localStorage.getItem('role')) === 'Admin'
    )
      MENUS.push({
        path: 'balance',
        title: 'Transaction',
        permissions: [],
        icon: <DollarOutlined />,
        menuItems: [
          {
            path: ROUTES.SELLER_TRANSACTION,
            title: 'Seller transaction',
            permissions: [],
          },
          {
            path: ROUTES.SUPPLIER_TRANSACTION,
            title: 'Supplier transaction',
            permissions: [],
          },
        ],
      })
  }, [])

  useEffect(() => {
    var data = JSON.parse(localStorage.getItem('balance'))
    var username = JSON.parse(localStorage.getItem('username'))
    setUsername(username)
    setBalance(data)
  }, [])
  return (
    <Layout className={styles['site-layout-background']}>
      <Sider
        className={styles['site-layout-background']}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        <div
          style={{
            display: collapsed ? 'none' : '',
          }}>
          <div className={styles['logo_left_icon']}>
            <img
              className={styles['logo_left']}
              src={settingApp.link_logo || ''}
              alt=""
            />
          </div>
        </div>
        <div
          className={
            collapsed ? styles['level_left_active'] : styles['level_left']
          }>
          Your level
        </div>
        <div
          className={
            collapsed ? styles['gold_left_active'] : styles['gold_left']
          }>
          {dataUser.level && dataUser.level.name}
        </div>
        <Menu
          className={styles['site-layout-background']}
          theme="dark"
          mode="inline"
          selectedKeys={routeMatch.path}>
          {MENUS.map(renderMenuItem)}
          <Menu.Item onClick={onClickLogout} key="9" icon={<LogoutOutlined />}>
            <Link to="/login">Logout</Link>
          </Menu.Item>
          <Menu.Item key="10">Version: {VERSION_APP}</Menu.Item>
        </Menu>
      </Sider>
      <Layout className={styles['site-layout']}>
        <div className={styles['header_custom']}>
          <div className={styles['header_custom_toggle']}>
            <MenuFoldOutlined
              style={{ fontSize: '1.25rem', margin: '0 0' }}
              onClick={toggle}
            />
          </div>
          <div className={styles['header_custom_right']}>
            <Popover content={content}>
              <div className={styles['header_custom_right_users']}>
                <div>
                  <img
                    className={styles['header_custom_right_users_img']}
                    src="https://ecomfullfillment.s3.ap-southeast-1.amazonaws.com/1625138766309_ecomfullfillment.png"
                    alt=""
                  />
                </div>
                <div className={styles['header_custom_right_users_child']}>
                  <div
                    className={
                      styles['header_custom_right_users_child_username']
                    }
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {username}
                  </div>
                  <div
                    className={
                      styles['header_custom_right_users_child_username_money']
                    }>
                    ${balance.available_amount}
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  )
}
