import React, { useEffect } from 'react'

import Views from './views'
import Loading from 'components/loading/Loading'
import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import { getAppSetting } from 'apis/app-setting'

export default function App() {
  const dispatch = useDispatch()

  //config notification
  notification.config({ duration: 2 })

  const _getSettingApp = async () => {
    try {
      const res = await getAppSetting()
      if (res.status === 200) {
        if (res.data.data) {
          document.querySelector("link[rel*='icon']").href =
            res.data.data.link_logo || ''
          dispatch({ type: 'GET_SETTING_APP', data: res.data.data })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getSettingApp()
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      dispatch({
        type: ACTION.LOGIN,
        data: {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken'),
        },
      })
    }
  }, [])

  return (
    <>
      <Loading />
      <Views />
    </>
  )
}
