import { get, post, patch, destroy } from './httpClient';

export const getAccountCredit = (params) =>
  get('/account-credits/get-account-credit', params);
export const getPaygate = (params) =>
  get('/account-credits/get-paygate', params);
export const createAccountCredit = (data) =>
  post('/account-credits/create-account-credit', data);
export const addPaygate = (data) =>
  post('/account-credits/create-paygate', data);
export const updateAccountCredit = (id, data) =>
  patch(`/account-credits/update-account-credit/${id}`, data);
export const deletePaygate = (params) =>
  destroy(`/account-credits/delete-paygate`, params);
export const checkingProxy = (data) =>
  post('/account-credits/check-proxy', data);
