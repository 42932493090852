import React from 'react'

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { ROUTES } from 'consts'

//base layout
import UI from 'components/Layout/UI'
import Authentication from 'components/authentication'

//views
import Login from './login'
import NotFound from './not-found/404'
import Mail from './mail'
import Users from './users'
import ForgotPassword from './forgot-password'
import NewPassword from './new-password'
import DisputeManagement from './dispute'
import AccountCredit from './account-credit'
import DisputeDetail from './dispute/detail'

const DEFINE_ROUTER = [
  {
    path: ROUTES.USERS,
    Component: () => <Users />,
    title: 'Users',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.DISPUTE,
    Component: () => <DisputeManagement />,
    exact: true,
    title: 'Dispute Management',
    permissions: [],
  },
  {
    path: ROUTES.DISPUTE_DETAIL,
    Component: () => <DisputeDetail />,
    exact: true,
    title: 'Dispute Detail',
    permissions: [],
  },
  {
    path: ROUTES.ACCOUNT_SETTING,
    Component: () => <AccountCredit />,
    exact: true,
    title: 'Account Setting',
    permissions: [],
  },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: 'Login to fullfillment',
    permissions: [],
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    Component: () => <ForgotPassword />,
    exact: true,
    title: 'Forgot password',
    permissions: [],
  },
  {
    path: ROUTES.NEW_PASSWORD,
    Component: () => <NewPassword />,
    exact: true,
    title: 'New password',
    permissions: [],
  },
  {
    path: ROUTES.MAIL,
    Component: () => <Mail />,
    exact: true,
    title: 'Authentication otp',
    permissions: [],
  },
]

export default function Views() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.USERS} />
        </Route>

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <UI>
                <Component />
              </UI>
            </Authentication>
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
