// react
import React, { useEffect, useState } from 'react';
// style
import styles from './dispute.module.scss';
//  antd
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Select,
  Form,
  Modal,
  notification,
  DatePicker,
  Checkbox,
  Tag,
} from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

// apis
import {
  getDispute,
  getLabel,
  createLabel,
  updateLabel,
  updateDispute,
  syncDispute,
} from 'apis/dispute';
import { getPaygate } from 'apis/account-credit';

// lib
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

//function
import { getTimeRange } from 'utils';
import { ROUTES } from 'consts';

// component
import ListSort from './components/ListSort';
import ReactDragListView from 'react-drag-listview';
import disputeColumns from './disputeColumns';
import SettingColumns from 'components/setting-column';
const { Option } = Select;
const { RangePicker } = DatePicker;
const AddTagComponent = (props) => {
  const [editAble, setEditAble] = useState(false);
  const onSelect = async (value, message) => {
    try {
      const res = await updateDispute(props.id, {
        labels: [...new Set([...props.label, value])],
      });
      if (res.data.success) {
        notification.success({ message: `${message} successful` });
        setEditAble(false);
        props.getAllDispute();
      } else {
        console.log(res);
        notification.error({ message: `${message} Fail` });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Fail' });
    }
  };
  const removeTag = async (value) => {
    try {
      let tmp = [...props.label];
      tmp.splice(tmp.indexOf(value), 1);
      const res = await updateDispute(props.id, {
        labels: tmp,
      });
      if (res.data.success) {
        notification.success({ message: `Remove successful` });
        props.getAllDispute();
      } else {
        console.log(res);
        notification.error({ message: `Remove Fail` });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Fail' });
    }
  };
  return (
    <>
      {props.label && typeof props.label == 'string' ? (
        <Tag
          color={props.data['color']}
          closable
          onClose={() => removeTag(props.data['label_id'])}
        >
          {props.data['name']}
        </Tag>
      ) : (
        props.label.length > 0 &&
        props.data &&
        props.data.map(
          (e) =>
            e && (
              <Tag
                color={e['color']}
                closable
                onClose={() => removeTag(e['label_id'])}
              >
                {e['name']}
              </Tag>
            )
        )
      )}

      {editAble ? (
        <Select
          style={{ width: '100%' }}
          value=""
          size="small"
          onSelect={(e) => onSelect(e, 'Add Label')}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.labelList.map((e) => (
            <Option value={e.label_id}>{e.name}</Option>
          ))}
        </Select>
      ) : (
        <Tag onClick={() => setEditAble(true)} style={{ cursor: 'pointer' }}>
          <PlusOutlined /> Add tag
        </Tag>
      )}
    </>
  );
};

export default function DisputeManagement() {
  const [disputeList, setDisputeList] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showLabelList, setShowLabelList] = useState(false);
  const [labelList, setLabelList] = useState([]);
  const [filter, setFilter] = useState({
    open_case: true,
    page: 1,
    page_size: 10,
  });
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [showDueDateFilter, setShowDueDateFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [syncLoading, setSyncLoading] = useState(false);
  const [showCase, setShowCase] = useState('open');
  const [showUpdateDispute, setShowUpdateDispute] = useState(false);
  const [dataUpdateDispute, setDataUpdateDispute] = useState({});
  const [paygateFilterList, setPaygateFilterList] = useState([]);
  const [columns, setColumns] = useState(
    localStorage.getItem('DisputeColumns')
      ? JSON.parse(localStorage.getItem('DisputeColumns'))
      : [...disputeColumns]
  );

  const [form] = Form.useForm();
  const history = useHistory();

  const renderData = {
    0: {
      width: 70,
      render(data, record) {
        return (
          <a
            href={`${ROUTES.DISPUTE_DETAIL}?dispute_id=` + record.dispute_id}
            style={{
              color: 'blue',
              fontWeight: 600,
              textTransform: 'capitalize',
              cursor: 'pointer',
            }}
          >
            {data}
          </a>
        );
      },
    },
    1: {
      sorter: true,
      render(data) {
        return {
          props: { style: { width: 220 } },
          children: data,
        };
      },
    },
    2: {
      sorter: true,
      render(data) {
        return {
          props: { style: { width: 220 } },
          children: data,
        };
      },
    },
    3: {
      sorter: true,
      render(data) {
        return {
          props: { style: { width: 230 } },
          children: data,
        };
      },
    },
    4: {
      sorter: true,
      render(data) {
        return {
          props: {
            style: { width: 130 },
          },
          children: <>{data}</>,
        };
      },
    },
    5: {
      sorter: true,
      render(data) {
        return {
          props: {
            style: { width: 180 },
          },
          children: <>{data}</>,
        };
      },
    },
    6: {
      sorter: true,
      render(data) {
        return {
          props: {
            style: { width: 230 },
          },
          children: (
            <>{data && data.customer_detail && data.customer_detail.name}</>
          ),
        };
      },
    },
    7: {
      sorter: true,
      render(data) {
        return {
          props: { style: { width: 190 } },
          children: data,
          // children: moment
          //   .tz(data, 'America/Los_Angeles')
          //   .format('DD-MM-YYYY hh:mm:ss'),
        };
      },
    },
    8: {
      sorter: true,
      render(data) {
        return {
          props: { style: { width: 190 } },
          children: data,
          // children: moment
          //   .tz(data, 'America/Los_Angeles')
          //   .format('DD-MM-YYYY hh:mm:ss'),
        };
      },
    },
    9: {
      width: 300,
      render(data) {
        return (
          data.dispute_detail &&
          data.dispute_detail.messages &&
          data.dispute_detail.messages[0].content
        );
      },
    },
    10: {
      sorter: true,
      render(data) {
        return {
          props: {
            style: { width: 240 },
          },
          children: <>{data}</>,
        };
      },
    },
    11: {
      render(data, record) {
        return {
          props: {
            style: { width: 190 },
          },
          children: (
            <>
              {!data ? (
                <EditOutlined
                  onClick={() => {
                    setDataUpdateDispute({
                      id: record._id,
                      key: 'tracking',
                      value: '',
                    });
                    setShowUpdateDispute(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <span
                  onClick={() => {
                    setDataUpdateDispute({
                      id: record._id,
                      key: 'tracking',
                      value: data,
                    });
                    setShowUpdateDispute(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {data}
                </span>
              )}
            </>
          ),
        };
      },
    },
    13: {
      render(data, record) {
        return {
          props: {
            style: { width: 150 },
          },
          children: (
            <>
              {!data ? (
                <EditOutlined
                  onClick={() => {
                    setDataUpdateDispute({
                      id: record._id,
                      key: 'note',
                      value: '',
                    });
                    setShowUpdateDispute(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <span
                  onClick={() => {
                    setDataUpdateDispute({
                      id: record._id,
                      key: 'note',
                      value: data,
                    });
                    setShowUpdateDispute(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {data}
                </span>
              )}
            </>
          ),
        };
      },
    },
  };

  const rowSelection = {
    selectedRowKeys: selectedLabel,
    onChange: (selectedRowKeys) => setSelectedLabel(selectedRowKeys),
  };
  const getAllDispute = async (params) => {
    try {
      setLoading(true);
      const res = await getDispute({ ...params });
      if (res.data.success) {
        setDisputeList(res.data.data);
        setTotalRecord(res.data.count);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const SyncDispute = async () => {
    try {
      setSyncLoading(true);
      const res = await syncDispute();
      if (res.data.success) {
        notification.success({ message: 'Sync dispute success' });
        // getAllDispute(filter);
      } else {
        notification.error({ message: 'Sync dispute fail' });
      }
      setSyncLoading(false);
    } catch (e) {
      setSyncLoading(false);
      notification.error({ message: 'Sync fail' });
      console.log(e);
    }
  };
  const getAllLabel = async (params) => {
    try {
      const res = await getLabel(params);
      if (res.data.success) {
        setLabelList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  function removeNullParams(a) {
    return Object.keys(a)
      .filter((key) => a[key] !== '' && a[key] !== undefined)
      .reduce((res, key) => ((res[key] = a[key]), res), {});
  }
  const createNewLabel = async (value) => {
    try {
      const body = {
        name: value.name,
        color: value.color,
        description: '',
      };
      const res = await createLabel(body);
      if (res.data.success) {
        notification.success({ message: 'Create Label Successful' });
        setShowCreate(false);
        getAllLabel();
        form.resetFields();
      } else {
        notification.success({ message: 'Create Label Fail' });
      }
    } catch (err) {
      console.log(err);
      notification.success({ message: 'Create Label Fail' });
    }
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tmpColumns = [...columns];
      const item = tmpColumns.splice(fromIndex, 1)[0];
      tmpColumns.splice(toIndex, 0, item);
      localStorage.setItem('DisputeColumns', JSON.stringify(tmpColumns));
      setColumns(tmpColumns);
    },
    nodeSelector: 'th',
  };

  // const changePagination = (page, page_size) => {
  //   console.log({ page, page_size });
  //   setFilter({ page, page_size });
  // };
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect);
  const toggleDueDate = () => setShowDueDateFilter(!showDueDateFilter);
  const changeRange = (dateString, prefix) => {
    setFilter({
      ...filter,
      [prefix + 'from_date']: dateString[0],
      [prefix + 'to_date']: dateString[1],
      page: 1,
      page_size: 10,
    });
  };
  const changeTimeOption = (value, prefix) => {
    let tmp = getTimeRange(value);
    setFilter({
      ...filter,
      [prefix + 'from_date']: tmp.from_date,
      [prefix + 'to_date']: tmp.to_date,
      page: 1,
      page_size: 10,
    });
  };
  const deleteLabel = async () => {
    try {
      const res = await Promise.all(
        selectedLabel.map((e) => {
          return updateLabel(e, { delete: true });
        })
      );
      if (res.reduce((a, b) => a && b.data.success, true)) {
        setSelectedLabel([]);
        getAllLabel();
        notification.success({ message: 'Delete successful' });
      } else {
        notification.error({ message: 'Delete fail' });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Fail' });
    }
  };

  const UpdateDispute = async (message = 'Update') => {
    try {
      const res = await updateDispute(dataUpdateDispute.id, {
        [dataUpdateDispute.key]: dataUpdateDispute.value,
      });
      if (res.data.success) {
        notification.success({ message: `Update successful` });
        setShowUpdateDispute(false);
        getAllDispute(filter);
      } else {
        console.log(res);
        notification.error({ message: `Update Fail` });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Fail' });
    }
  };

  const getListPaygateName = async () => {
    try {
      const res = await getPaygate({
        username:
          localStorage.username && localStorage.username.replace(/\"/g, ''),
      });
      if (res.data.success) {
        setPaygateFilterList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllLabel();
  }, []);
  useEffect(() => {
    if (!localStorage.getItem('DisputeColumns')) {
      localStorage.setItem('DisputeColumns', JSON.stringify(disputeColumns));
    }
  }, []);
  useEffect(() => {
    getListPaygateName();
  }, []);
  useEffect(() => {
    getAllDispute(removeNullParams(filter));
  }, [filter]);

  // useEffect(() => {
  //   getAllDispute(removeNullParams({ ...filter }));
  // }, [filter]);
  return (
    <div className={styles['dispute']}>
      <Row
        justify="space-between"
        style={{
          borderBottom: 'solid 1px #A8A1A1',
          paddingBottom: '15px',
          marginBottom: 20,
        }}
      >
        <Col className={styles['dispute-title']}>Dispute Management</Col>
        <Col>
          <Row gutter={20}>
            <Col>
              <Button
                type="primary"
                style={{ backgroundColor: '#3AAC30', border: 'none' }}
                onClick={() => setShowLabelList(true)}
              >
                Label List
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setShowCreate(true)}>
                Create Label
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
        <Col span={6}>
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
            placeholder="Search by order code, dispute code"
            onChange={(e) => {
              setFilter({
                ...filter,
                search: e.target.value,
                page: 1,
                page_size: 10,
              });
            }}
          />
        </Col>
        <Col span={6}>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect();
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect();
            }}
            style={{ width: '100%' }}
            placeholder="Filter by time"
            allowClear
            onChange={async (value) => {
              if (isOpenSelect) toggleOpenSelect();
              changeTimeOption(value, 'create_');
            }}
            dropdownRender={(menu) => (
              <div>
                <RangePicker
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect();
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect();
                  }}
                  style={{ width: '100%' }}
                  onChange={(date, dateString) =>
                    changeRange(dateString, 'create_')
                  }
                />
                {menu}
              </div>
            )}
          >
            <Option value="to_day">Today</Option>
            <Option value="yesterday">Yesterday</Option>
            <Option value="this_week">This week</Option>
            <Option value="last_week">Last week</Option>
            <Option value="last_month">Last month</Option>
            <Option value="this_month">This month</Option>
            <Option value="this_year">This year</Option>
            <Option value="last_year">Last year</Option>
          </Select>
        </Col>

        <Col span={6}>
          <Select
            open={showDueDateFilter}
            onBlur={() => {
              if (showDueDateFilter) toggleDueDate();
            }}
            onClick={() => {
              if (!showDueDateFilter) toggleDueDate();
            }}
            style={{ width: '100%' }}
            placeholder="Filter by due date"
            allowClear
            onChange={async (value) => {
              if (showDueDateFilter) toggleDueDate();
              changeTimeOption(value, 'due_');
            }}
            dropdownRender={(menu) => (
              <div>
                <RangePicker
                  onFocus={() => {
                    if (!showDueDateFilter) toggleDueDate();
                  }}
                  onBlur={() => {
                    if (showDueDateFilter) toggleDueDate();
                  }}
                  style={{ width: '100%' }}
                  onChange={(date, dateString) =>
                    changeRange(dateString, 'due_')
                  }
                />
                {menu}
              </div>
            )}
          >
            <Option value="to_day">Today</Option>
            <Option value="yesterday">Yesterday</Option>
            <Option value="this_week">This week</Option>
            <Option value="last_week">Last week</Option>
            <Option value="last_month">Last month</Option>
            <Option value="this_month">This month</Option>
            <Option value="this_year">This year</Option>
            <Option value="last_year">Last year</Option>
          </Select>
        </Col>

        <Col span={6}>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Filter by status"
            onChange={(status) => {
              setFilter({
                ...filter,
                status,
                page: 1,
                page_size: 10,
              });
            }}
          >
            <Select.Option value="open">Open</Select.Option>
            <Select.Option value="waiting_for_buyer_response">
              Waiting for buyer response
            </Select.Option>
            <Select.Option value="waiting_for_seller_response">
              Waiting for seller response
            </Select.Option>
            <Select.Option value="under_review">Under review</Select.Option>
            <Select.Option value="resolve">Resolve</Select.Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Filter by dispute channel"
            onChange={(e) => {
              setFilter({
                ...filter,
                chanel: e,
                page: 1,
                page_size: 10,
              });
            }}
          >
            <Select.Option value="paypal">Paypal</Select.Option>
            <Select.Option value="stripe">Stripe</Select.Option>
          </Select>
        </Col>

        <Col span={6}>
          <Select
            allowClear
            style={{ width: '100%' }}
            mode="multiple"
            placeholder="Filter by label"
            onChange={(e) => {
              setFilter({
                ...filter,
                labels: e.join('-'),
                page: 1,
                page_size: 10,
              });
            }}
          >
            {labelList.map((e) => (
              <Option value={e.label_id}>{e.name}</Option>
            ))}
          </Select>
        </Col>

        <Col span={6}>
          <Select
            placeholder="Filter by paygate name"
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            onChange={(e) => {
              setFilter({
                ...filter,
                paygate_name: e.join('00000'),
                page: 1,
                page_size: 10,
              });
            }}
          >
            {paygateFilterList[0] &&
              paygateFilterList[0].paygates.map((e) => (
                <Select.Option value={e}>{e}</Select.Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }} justify="space-between">
        <Col>
          <Button
            type={showCase == 'open' && 'primary'}
            style={{ marginRight: 10 }}
            onClick={() => {
              if (showCase == 'open') {
                setShowCase('default');
                let tmp = { ...filter };
                delete tmp.open_case;
                setFilter({ ...tmp, page: 1, page_size: 10 });
              } else {
                setShowCase('open');
                let tmp = { ...filter };
                delete tmp.close_case;
                setFilter({
                  ...tmp,
                  open_case: true,
                  page: 1,
                  page_size: 10,
                });
              }
            }}
          >
            Open Case
          </Button>
          <Button
            type={showCase == 'close' && 'primary'}
            onClick={() => {
              if (showCase == 'close') {
                setShowCase('default');
                let tmp = { ...filter };
                delete tmp.close_case;
                setFilter({ ...tmp, page: 1, page_size: 10 });
              } else {
                setShowCase('close');
                let tmp = { ...filter };
                delete tmp.open_case;
                setFilter({
                  ...tmp,
                  close_case: true,
                  page: 1,
                  page_size: 10,
                });
              }
            }}
          >
            Close Case
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            onClick={SyncDispute}
            loading={syncLoading}
          >
            Sync
          </Button>
          <SettingColumns
            width={800}
            columnsRender={disputeColumns}
            columns={columns}
            setColumns={setColumns}
            nameColumn="DisputeColumns"
          />
        </Col>
      </Row>
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          rowKey="id"
          size="small"
          loading={loading}
          columns={columns.map((e) => {
            if (e.key === 12) {
              return {
                ...e,
                width: 200,
                render(data, record) {
                  return (
                    <AddTagComponent
                      labelList={labelList}
                      data={data}
                      label={record.labels}
                      id={record._id}
                      getAllDispute={() => getAllDispute(filter)}
                    />
                  );
                },
              };
            }
            if (renderData[e.key]) {
              return { ...e, ...renderData[e.key] };
            }
            return e;
          })}
          dataSource={[...disputeList]} //disputeList
          scroll={{ x: 'max-content' }}
          pagination={{
            // onChange: changePagination,
            total: totalRecord,
            current: filter.page,
          }}
          onChange={(pagination, filters, sorter) => {
            console.log(pagination);
            if (
              sorter.field === 'extension' &&
              sorter.column &&
              sorter.column.title === 'Customer name'
            )
              setFilter({
                ...filter,
                page: pagination.current,
                page_size: pagination.pageSize,
                sort:
                  sorter.order && `name:${sorter.order == 'descend' ? -1 : 1}`,
              });
            else
              setFilter({
                ...filter,
                page: pagination.current,
                page_size: pagination.pageSize,
                sort:
                  sorter.order &&
                  `${sorter.field}:${sorter.order == 'descend' ? -1 : 1}`,
              });
          }}
          sticky
        />
      </ReactDragListView.DragColumn>
      <Modal
        title="Create label"
        visible={showCreate}
        width={700}
        footer=""
        onCancel={() => setShowCreate(false)}
      >
        <Form layout="vertical" onFinish={createNewLabel}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
              >
                <Input size="large" style={{ borderRadius: 8 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="color"
                label="Color"
                rules={[
                  {
                    required: true,
                    message: 'Color is required',
                  },
                ]}
              >
                <Input
                  size="large"
                  style={{ borderRadius: 8 }}
                  placeholder="#261F1F"
                  type="color"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: 8 }}
            >
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Label List"
        footer=""
        visible={showLabelList}
        onCancel={() => setShowLabelList(false)}
        width={600}
      >
        <Row style={{ marginBottom: 20 }}>
          {selectedLabel.length > 0 && (
            <Button type="primary" danger onClick={deleteLabel}>
              Delete
            </Button>
          )}
        </Row>
        <Table
          rowSelection={rowSelection}
          rowKey="label_id"
          columns={[
            { title: 'Label', dataIndex: 'name' },
            {
              title: 'Color',
              dataIndex: 'color',
              render: (data) => <span style={{ color: data }}>{data}</span>,
            },
          ]}
          size="small"
          dataSource={labelList}
        />
      </Modal>

      <Modal
        title="Update dispute"
        centered
        visible={showUpdateDispute}
        onCancel={() => setShowUpdateDispute(false)}
        onOk={UpdateDispute}
      >
        <Input.TextArea
          value={dataUpdateDispute.value}
          onChange={(e) =>
            setDataUpdateDispute({
              ...dataUpdateDispute,
              value: e.target.value,
            })
          }
        />
      </Modal>
    </div>
  );
}
